import React from 'react'

export default function Mentor({name, image, description}) {


  return (
    <div>
      <div className="mentor-wrapper" >
        <div className="mentor" >
          <img width="64" src={`./media/mentors/${image}`} alt={name} />         
          <p className="mentor-name" >{name}</p>
          <p className="mentor-about"  >{description}</p>
        </div>
      </div>
    </div>
  )
}