import { useEffect, useMemo, useState } from "react";
import Logo from "./components/Logo";
import Mentor from "./components/Mentor";
import Pagination from './components/Pagination';
import Technology from "./components/Technology";

const fullYear = new Date().getFullYear();

function App() {

  // Mobile nav state
  const [openMobileNav, setOpenMobileNav] = useState(false);
  function toggleOpenMobileNav() {
    setOpenMobileNav(_value => !_value);
  }
  const navClass = useMemo(() => openMobileNav ? 'open ' : ' ', [openMobileNav]);


  // Header scroll box-shadow
  const [offset, setOffset] = useState(0);
  function onScrollListener(e) {
    setOffset(document.body.getBoundingClientRect().top);
  }
  useEffect(() => {
    window.addEventListener("scroll", onScrollListener);
    return () => {
      window.removeEventListener("scroll", onScrollListener);
    };
  }, []);

  const [showForm, setShowForm] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setShowForm(true);
    }, 2000)
  }, [])

  return (
    <div className="App">

      {openMobileNav &&
        <style>{`
          @media only screen and (max-width: 900px) {
            body {
              overflow: hidden;
            }
          }
        `}</style>
      }

      <div className={`header-container ${offset <= 0 ? '' : 'scrolling'}`}>
        <header>
          {/* <h3>
            skill
            <span className="highlight" >up</span>
            <br className="nav-mobile-only" />
            mentor
            <span className="highlight" >.</span>
          </h3> */}


          <Logo />


          <div onClick={toggleOpenMobileNav} className="nav-mobile-only nav-toggle-button nav-menu-icon " >
            <img src="./media/icons/menu.svg" alt="menu" />
          </div>


          <nav className={navClass} >

            <div className="nav-mobile-header  nav-mobile-only"  >
              <div onClick={toggleOpenMobileNav} className="nav-toggle-button" >
                <img src="./media/icons/icon-x.svg" />
              </div>
            </div>

            <ul>
              <li><a href="#technologies" onClick={() => setOpenMobileNav(false)} >Technologies</a></li>
              <li><a href="#mentors" onClick={() => setOpenMobileNav(false)} >Mentors</a></li>
              <li><a href="#companies" onClick={() => setOpenMobileNav(false)} >Companies</a></li>
              {/* <li><a href="#reviews" onClick={() => setOpenMobileNav(false)} >Reviews</a></li> */}
              <li><a href="#courses" onClick={() => setOpenMobileNav(false)} >Courses</a></li>
              <li className="nav-mobile-only" onClick={() => setOpenMobileNav(false)}><a onClick={() => setOpenMobileNav(false)} href="#form" >Apply now</a></li>
            </ul>

          </nav>

          <a className="nav-desktop-only link_button" href="#form" >Apply now</a>

        </header>
      </div>






      <section id="skillup" className="skillup" >
        <div className="skillup-text" >
          <h1>
            Skill
            <span className="highlight" > up </span>
            with help of our
            <span className="highlight"> mentors.</span>
          </h1>

          <p className="margin" >
            SkillUp Mentor is a project-oriented learning program that builds up your programming skills and gets you hired by the best companies. Apply now if you are looking for a job and just lack new skills.
          </p>

          <a className="link_button" href="#form" >Apply now</a>
        </div>

        <div className="skillup-image"   >
          <img src="./media/first.jpg" alt="" />
        </div>
      </section>






      <section id="how-it-works"  >
        <h2>
          How it
          <span className="highlight" > works.</span>
        </h2>

        <p>With the help of our mentors who work at our partner companies, you'll get the programming skills you need to get hired by great employers.</p>
        <p>You will build 5 production-ready projects and learn about technologies currently being used by companies. With these skills, you will be able to apply for jobs at these companies.</p>
        <p>SkillUp Mentor is a remote program where you set your own pace. Therefore, you can do it without leaving your current job, even while studying...</p>
        <p>Pricing? If you get selected – <b className="highlight" >  IT’S FREE!</b></p>
      </section>





      <section id="technologies" >
        <div class="technologies-grid"  >
          <h2>
            Advance
            <span className="highlight" > technologies.</span>
          </h2>

          <p>
            Here are some technologies you will encounter during the program.
          </p>
        </div>

        <div className="technologies">
          <Technology name="js" label="JavaScript" />
          <Technology name="typescript" label="TypeScript" />
          <Technology name="node" label="NodeJS" />

          <div className="techGridBreak" ></div>

          <Technology name="tailwind" label=" Tailwind CSS" />
          <Technology name="react" label="React" />
          <Technology name="docker" label="Docker" />
          <Technology name="figma" label="Figma" />

          <div className="techGridBreak" ></div>

          <Technology name="nest" label="NestJS" />
          <Technology name="fastify" label="Fastify" />
          <Technology name="aws" label="AWS" />
        </div>
      </section>





      <section id="mentors" className="mentors-section fill">
        <div>
          <h2>
            Meet our great
            <span className="highlight" > mentors.</span>
          </h2>

          <p>
            We know that getting started is an important and sometimes difficult process. Our mentors are aware of that. We are here to help you gain experience and skills along the way.
            We also want to work with great coworkers.
          </p>

          <div style={{ height: '2em' }} ></div>

          <Pagination total={5}  >
            <Mentor
              name="Klemen Plazar"
              description="Developer at Povio, lead mentor at SkillUp mentor"
              image="klemen-plazar.jpeg"
            />

            <Mentor
              name="Islam Mušić"
              description="Professor at school, developer and nerd"
              image="slika_islam.jpg"
            />

            <Mentor
              name="Jure Plešej"
              description="Developer at Equaleyes, runs on coffee and JavaScript"
              image="jure_plesej.png"
            />

            <Mentor
              name="Jure Zemljič"
              description="Developer at SportsRadar, MacGyver among developers"
              image="jure-zemljic.jpeg"
            />

            <Mentor
              name="Marko Blagotinsek"
              description="Developer at SportsRadar, frontend magician"
              image="marko-blagotinsek.jpeg"
            />
          </Pagination>

        </div>
      </section>


      <section id="companies" >
        <h2>
          We work with amazing
          <span className="highlight" > companies.</span>
        </h2>

        <p>
          Our goal is to work with the best employers. In that way, we match the best companies with our graduates who have the best skills. Here are some employers we are already working with.
        </p>

        <div className="companies" >

          <a href="https://povio.com/" target="_blank" className="company" >
            <img src="./media/companies/povio.png" />
          </a>

          <a href="https://equaleyes.com/" target="_blank" className="company" >
            <img src="./media/companies/eq.png" />
          </a>

          <a href="https://www.sportradar.com/" target="_blank" className="company" >
            <img src="./media/companies/sportradar.png" />
          </a>

          <a href="https://www.meettippy.com/" target="_blank" className="company" >
            <img src="./media/companies/tippy.png" />
          </a>
        </div>

      </section>




      {/* <section id="reviews" >
        <h2>
          What people think of
          <span className="highlight" > us.</span>
        </h2>

        <p>
          Lorem ipsum dolor sit amet, consectetur elit. Libero, enim nibh vitae massadiam id. Ac
          suspendisse sagittis, eros.
        </p>

        <div className="opinions" >
          <div className="opinion" >
            <div className="opinion-top">
              <img width="32px" alt="avatar" src="./media/technologies/react.png" />
              <div className="opinion-person-container" >
                <p className="opinion-person-name" >Mika jones</p>
                <p className="opinion-person-profession" >Produkt dezajner</p>
              </div>
            </div>
            
            <p className="opinion-content" >
              Lorem ipsum dolor sit amet, consectetur elit. Libero, enim nibh vitae massadiam id. Ac
              suspendisse sagittis, eros.
            </p>
          </div>

        </div>
      </section> */}



      <section id="courses" >
        <h2>
          Discover our
          <span className="highlight" > courses.</span>
        </h2>

        <p >
          Currently, we are offering a Full-stack Web developer course based on JavaScript technologies. For other technologies contact us via email - <a className="email" ahref="mailto:hey@skillupmentor.com" >hey@skillupmentor.com</a>
        </p>

      </section>

      <section id="form" >
        <h2>
          Apply now and skill
          <span className="highlight" > up.</span>
        </h2>

        <p style={{ marginBottom: 0 }}>
          Insert your information in the form bellow.
        </p>
        {/* 
        <iframe style={{
          display: showForm ? 'block' : 'none'
        }} id="formEmbed" src="https://v2a1kaykkcg.typeform.com/to/ZAVs34Oy" ></iframe> */}

        <iframe
          style={{
            display: showForm ? 'block' : 'none',
            maxWidth: "100%",
            width: "100%",
            border: "none",
            height: 1100,
          }}

          id="formEmbed JotFormIFrame-222863155670054"
          title="SkillUp Mentor apply Form"
          onload="window.parent.scrollTo(0,0)"
          allowtransparency="true"
          allowfullscreen="true"
          allow="geolocation; microphone; camera"
          src="https://form.jotform.com/222863155670054"
          frameborder="0"
          scrolling="no"
        ></iframe>


      </section>






      <section id="discord" >
        <h2>
          We have a Slack
          <span className="highlight" > community.</span>
        </h2>

        <p >
          Once you apply, you will gain access to our Slack community.
        </p>

        {/* <a className="link_button" href="https://join.slack.com/t/skillupmentor/shared_invite/zt-t0l3qcmp-VnGbSjnv60SWOaJNM5YjgQ" target="_blank" >
          Click to join
        </a> */}

        <a className="link_button" href="#form" >Apply now</a>
      </section>





      <div className="footer-container" >
        <footer>
          {/* <h3>
            skill
            <span className="highlight" >up</span>
            mentor
            <span className="highlight" >.</span>
          </h3> */}

          <Logo />

          <span class="copyright" > &copy; {fullYear}</span>
        </footer>
      </div>
    </div>
  );
}

export default App;
