import React, { useEffect, useMemo, useState } from 'react'
import { useMediaQuery } from 'react-responsive';

export default function Pagination({children, total}) {

  const [page, setPage] = useState(0);

  function next() {
    // Don't go into a next page if we're at the last page already
    // This prevents a little animation glitch when trying to go over the max amount of pages
    if( page >= numOfPages - 1 ) return;
    setPage(_ => _ + 1);
  }

  function prev() {
    if( page == 0 ) return;
    setPage(_ => _ - 1);
  }

  
  let showing2 = useMediaQuery({
    query: '(max-width: 1200px)'
  })

  let showing1 = useMediaQuery({
    query: '(max-width: 800px)'
  })

  let trans = page * -100;

  const numOfPages = useMemo(() => {
    if(showing1) return total;
    if(showing2) return (Math.ceil(total / 2));
    return (Math.ceil(total / 3));
  }, [showing1, showing2, total])


  useEffect(() => {
    if(showing1 && page < total) return;
    if(showing2 && page * 2 < total) return;
    if(page * 3 < total) return;

    // We have an overflow, return the the user to the last valid page
    if(showing1) {
      setPage(total - 1);
    } else if(showing2) {
      setPage(Math.ceil(total / 2) - 1);
    } else {
      setPage(Math.ceil(total / 3) - 1);
    }

  }, [page, showing1, showing2])


  return (<>
    <div style={{overflow: 'hidden'}}  >
      <div className="pagination-content-wrapper" style={{
        transform: `translateX(${trans}%) translateZ(0)`,
      }} >
        {children} 
      </div>

      <div className="pagination-controls" >
        <button className="icon" onClick={prev}   > 
          <img src="./media/arrow_left.svg" />
        </button>

        {new Array(numOfPages).fill(0).map((_page, i) => (
          <div onClick={() => setPage(i)} className={`pageDot ${i === page ? 'active' : ''}`} ></div>
        ))}
        
        <button className="icon" onClick={next} > 
          <img src="./media/arrow_right.svg" />  
        </button>
      </div>
    </div>
  </>)
}
