import React from 'react'

export default function Technology({name, label}) {
  return (
    <div>
      <div className="technology">
        <img src={`./media/technologies/${name}.png`} alt={label}  />

        <div className="technology-overlay" >
          {label}
        </div>
      </div>
    </div>
  )
}
